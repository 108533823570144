// entry.client.js
import createApp from './src/app.js';

const { app, router } = createApp();

router.onReady(() => {
  updateMeta(router.currentRoute);

  router.beforeEach((to, from, next) => {
    updateMeta(to);
    next();
  });

  app.$mount('#app');
});

function updateMeta(route) {
  const meta = route.meta || {};
  const defaultDescript = `GBWhatsApp released a new version with enhanced anti-ban protection. Download GB WhatsApp APK to try those appealing features in our site.`
  const defaultKeyword = 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download'
  const defaultCanonical = 'https://gbapks.com.pk/';

  document.title = meta.title || 'GB WhatsApp APK Download with Anti-Ban Enhanced 2025';

  setMetaTag('title', meta.title || 'GB WhatsApp APK Download with Anti-Ban Enhanced 2025');
  setMetaTag('description', meta.description || defaultDescript);
  setMetaTag('og:description', meta.description || defaultDescript);
  setMetaTag('keywords', meta.keywords || defaultKeyword);

  setLinkTag('canonical', meta.link?.find(link => link.rel === 'canonical')?.href || defaultCanonical);
}

function setMetaTag(name, content) {
  let element = document.querySelector(`meta[name="${name}"]`);
  if (element) {
    element.setAttribute('content', content);
  } else {
    element = document.createElement('meta');
    element.setAttribute('name', name);
    element.setAttribute('content', content);
    document.head.appendChild(element);
  }
}
function setLinkTag(rel, href) {
  let element = document.querySelector(`link[rel="${rel}"]`);
  if (element) {
    element.setAttribute('href', href);
  } else {
    element = document.createElement('link');
    element.setAttribute('rel', rel);
    element.setAttribute('href', href);
    document.head.appendChild(element);
  }
}